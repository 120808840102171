import React, { useCallback, useRef, useState } from 'react';
import debounce from 'lodash.debounce';

import { ChatIcon, Footer, NewChat, NavPrompt, Loading, NavLinks, BotResponse, IntroSection } from 'components';

function App() {
  const apiBaseUrl = process.env.REACT_APP_APP_BASE_URL;
  const [showMenu, setShowMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputPrompt, setInputPrompt] = useState('');
  const [chatLog, setChatLog] = useState([]);
  const chatWrapperEndRef = useRef();
  const chatWrapperRef = useRef();
  const inputPromptRef = useRef();

  const callAPI = async () => {
    try {
      const response = await fetch(apiBaseUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ message: inputPrompt }),
      });
      const data = await response.json();
      setChatLog([
        ...chatLog,
        {
          chatPrompt: inputPrompt,
          botMessage: data.response,
        },
      ]);

      // Scroll to bottom if container overflows
      if (chatWrapperRef.current.scrollHeight > chatWrapperRef.current.offsetHeight) {
        scrollToBottom();
      }

      debouncedSave();
    } catch (error) {
      debouncedSave();
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (loading === false && inputPrompt?.length > 0) {
      inputPromptRef.current.blur(); // blur to hide keyboard on safari
      setLoading(true);
      setChatLog([...chatLog, { chatPrompt: inputPrompt }]);
      callAPI();
      setInputPrompt('');
    }
  };

  const debouncedSave = useCallback(
		debounce(() => setLoading(false), 1500),
		[],
	);

  const scrollToBottom = () => {
    chatWrapperEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div className='app overflow-hidden w-full h-full relative'>
      <header>
        <div className='menu'>
          <button onClick={() => setShowMenu(true)}>
            <svg
              width={24}
              height={24}
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              stroke='#d9d9e3'
              strokeLinecap='round'
            >
              <path d='M21 18H3M21 12H3M21 6H3' />
            </svg>
          </button>
        </div>
        <h1>ChatFDP</h1>
      </header>
      {showMenu && (
        <nav>
          <div className='nav-items'>
            <NewChat setChatLog={setChatLog} setShowMenu={setShowMenu} />
            {chatLog.map(
              (chat, idx) =>
                chat.botMessage && (
                  <NavPrompt chatPrompt={chat.chatPrompt} key={idx} />
                )
            )}
          </div>
          <div className='nav-close-icon'>
            <svg
              fill='#fff'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 100 100'
              xmlSpace='preserve'
              stroke='#fff'
              width={42}
              height={42}
              onClick={() => setShowMenu(false)}
            >
              <path d='m53.691 50.609 13.467-13.467a2 2 0 1 0-2.828-2.828L50.863 47.781 37.398 34.314a2 2 0 1 0-2.828 2.828l13.465 13.467-14.293 14.293a2 2 0 1 0 2.828 2.828l14.293-14.293L65.156 67.73c.391.391.902.586 1.414.586s1.023-.195 1.414-.586a2 2 0 0 0 0-2.828L53.691 50.609z' />
            </svg>
          </div>
        </nav>
      )}

      <aside className='side-menu'>
        <NewChat setChatLog={setChatLog} setShowMenu={setShowMenu} />
        <div className='nav-prompt-wrapper'>
          {chatLog.map(
            (chat, idx) =>
              chat.botMessage && (
                <NavPrompt chatPrompt={chat.chatPrompt} key={idx} />
              )
          )}
        </div>
        {chatLog.length > 0 && (
          <NavLinks
            svg={
              <svg
                fill='#fff'
                viewBox='0 0 24 24'
                data-name='Flat Line'
                xmlns='http://www.w3.org/2000/svg'
                className='icon flat-line'
                stroke='#fff'
                width={23}
                height={23}
              >
                <path
                  d='M5 8h13a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H5V8Z'
                  transform='rotate(90 12 14)'
                  style={{
                    fill: '#fff202022',
                    strokeWidth: 2,
                  }}
                />
                <path
                  d='M16 7V4a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v3'
                  style={{
                    fill: 'none',
                    stroke: '#fff202022000000',
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    strokeWidth: 2,
                  }}
                />
                <path
                  data-name='primary'
                  d='M10 11v6m4-6v6M4 7h16m-2 13V7H6v13a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1Z'
                  style={{
                    fill: 'none',
                    stroke: '#fff202022000000',
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    strokeWidth: 2,
                  }}
                />
              </svg>
            }
            text='Clear Conversations'
            setChatLog={setChatLog}
          />
        )}
        <NavLinks
          svg={
            <svg
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              width={25}
              height={25}
            >
              <path
                stroke='#fff'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M12 6H7a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-5m-6 0 7.5-7.5M15 3h6v6'
              />
            </svg>
          }
          text='MeanAI website'
          link='https://kinoba.fr/'
        />
      </aside>

      <section className='chat-box flex h-full flex-1 flex-col md:pl-[260px]'>
        {chatLog.length > 0 ? (
          <div ref={chatWrapperRef} className='chat-log-wrapper'>
            {chatLog.length > 0 &&
              chatLog.map((chat, idx) => (
                <div className='chat-log' key={idx}>
                  <div className='chat-prompt-main-container'>
                    <div className='chat-prompt-wrapper'>
                      <ChatIcon bg='#5437DB' className='user-icon'>
                        <svg height="41" width="41"  stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="h-6 w-6" xmlns="http://www.w3.org/2000/svg"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                      </ChatIcon>
                      <div id='chatPrompt' className="chat-prompt">{chat.chatPrompt}</div>
                    </div>
                  </div>

                  <div className='bot-message-main-container'>
                    <div className='bot-message-wrapper'>
                      <ChatIcon bg='#11a27f' className='openai-icon'>
                        <svg width="41" height="41" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M4 14L14 2L12.9545 10H20L10 22L11.0455 14H4Z" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                      </ChatIcon>
                      {chat.botMessage ? (
                        <div id='botMessage' className="bot-message">
                          <BotResponse response={chat.botMessage} />
                        </div>
                      ) : <Loading /> }
                    </div>
                  </div>
                </div>
              ))}
              <div ref={chatWrapperEndRef} />
          </div>
        ) : (
          <IntroSection />
        )}

        <div className='input-prompt-wrapper'>
          <form onSubmit={handleSubmit}>
            <div className='input-prompt'>
              <div>
                <input
                  ref={inputPromptRef}
                  name='inputPrompt'
                  className='input-prompt-textarea'
                  type='text'
                  rows='1'
                  value={inputPrompt}
                  onChange={(e) => setInputPrompt(e.target.value)}
                  autoFocus
                ></input>
                <button className="input-prompt-icon" type="submit">
                  <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="h-4 w-4 mr-1" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg>
                </button>
              </div>
            </div>
          </form>
          <Footer />
        </div>
      </section>
    </div>
  );
}

export default App;
