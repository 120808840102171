import React from 'react';

const ChatIcon = ({ children, bg, className }) => {
  return (
    <div className='chat-icon' style={{ backgroundColor: `${bg}` }}>
      <div className={className}>{children}</div>
    </div>
  );
};

export default ChatIcon;
