import React from 'react';

const Footer = () => {
  return (
    <div className="footer">
      <div>ChatFDP 1.0 Version.
      Free Research Preview. Our goal is to make AI systems more fun and useless. Your feedback will help us improve.</div>
      <div className="editor">Made for fun, by <a href="https://kinoba.fr/" target="_blank" rel="noreferrer" className="underline">Kinoba</a>.</div>
    </div>
  );
};

export default Footer;
