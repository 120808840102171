import React, { useEffect, useState } from 'react';

const BotResponse = ({ response }) => {
  const [botResoponse, setBotResponse] = useState('');

  useEffect(() => {
    let index = 1;
    let msg = setInterval(() => {
      setBotResponse(response.slice(0, index));
      if (index >= response.length) {
        clearInterval(msg);
      }
      index++;
    }, 30);
  }, [response]);

  return (
    <>
      {botResoponse}
      {botResoponse === response ? '' : <span className='bot-cursor'></span>}
    </>
  );
};

export default BotResponse;
