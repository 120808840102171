import React from 'react';

const IntroSection = () => {
  return (
    <div className="intro-section">
      <h1>
        ChatFDP
      </h1>
    </div>
  );
};

export default IntroSection;
